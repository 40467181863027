<template>
  <v-app fluid>

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Cinema Accademia",
      meta: [
        { name: 'description', content:  'cinema accademia pontassieve'},
        { property: 'og:title', content: "cinema accademia"},
        { property: 'og:site_name', content: 'cinema accademia'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }

};
</script>

<style>
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10rem;
}

::-webkit-scrollbar-track {
  background: rgb(100, 100, 100);
}
</style>
