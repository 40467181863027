import Vue from 'vue'
import Router from 'vue-router'
import db from "./firebaseConfiguration/firebaseInit";

Vue.use(Router)

let router = new Router({
    mode: '',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            name: 'home',
            redirect: '/',
            component: () => import('./views/HomeLayout.vue'),
            children: [{
                    path: '',
                    component: () => import('./views/HomePage.vue'),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    name: 'cinelandia',
                    path: 'cinelandia',
                    component: () => import('./views/Cinelandia.vue'),
                    meta: {
                        requiresAuth: false
                    }
                },
                {
                    name: 'about',
                    path: 'about',
                    component: () => import('./views/About.vue'),
                    meta: {
                        requiresAuth: false
                    }
                }
            ],
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import( /* webpackChunkName: "about" */ './views/About.vue')
        },
        {
            path: '/login',
            component: () => import('./views/Login.vue'),
        },
        {
            path: '/singnup',
            component: () => import('./views/SignUp.vue'),
        },
        {
            path: '/admin',
            name: 'Admin',
            redirect: '/admin/modify',
            component: () => import('./views/Admin.vue'),
            children: [{
                    name: 'create',
                    path: 'create',
                    component: () => import('./views/Create.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'detail',
                    path: 'detail',
                    component: () => import('./views/Detail.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },
                {
                    name: 'modify',
                    path: 'modify',
                    component: () => import('./views/Modify.vue'),
                    meta: {
                        requiresAuth: true
                    }
                },

            ],
            meta: {
                requiresAuth: true
            }

        }
    ]
})

router.beforeEach((to, from, next) => {
    let currentUser = db.auth().currentUser
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth && !currentUser) {
        next('/login')
    } else {
        next()
    }
})

export default router;