import Vue from 'vue'
import Vuex from 'vuex'
import {isMobile} from "mobile-device-detect";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: {name: "", password: ""},
        // isMobile: mobileVendor === 'Apple'
        isMobile: isMobile
    },
    getters: {
        auth(state) {
            return state.auth;
        }
    },
    mutations: {
        setAuth(state, auth) {
            state.auth = auth;
        }
    },
    actions: {
        SET_AUTH(context, auth) {
            context.commit("setAuth", auth);
        }
    }
})
