import firebase from 'firebase'
import 'firebase/firestore'
import firebaseConfig from './firebaseConfig'

const firebaseApp = firebase.initializeApp(firebaseConfig)


// if (location.hostname === "localhost") {
//     const db = firebase.firestore();
//     db.settings({
//         host: "localhost:8080",
//         ssl: false
//     });
// }


export default firebaseApp